import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { InformationCircleIcon } from '@heroicons/react/outline';
import Special from '../components/Special';
import timeConverter from '../lib/timeConverter';
import ContactUs from '../components/ContactUs';
import Divider from '../components/Divider';
import SEO from '../components/SEO';

export default function ClassesPage({ path }) {
  const { belts } = useStaticQuery(graphql`
  query {
    belts: allSanityBelt(sort: {order: ASC, fields: classes___belt___order}) {
        nodes {
        classes {
            end
            name
            start
            id
            belt {
                name
                id
            }
        }
        id
        name
        slug {
              current
            }
        image {
            asset {
            gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
    } 
  }
`);
  return (
    <>
      <SEO title="Classes" description="Learn more about Class Times to find a session that fits with your schedule." />
      <div className="px-4 mx-auto mt-32 mb-8 max-w-7xl sm:px-6">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">Check out our</span>
            <span className="block text-blue-600">class schedules</span>
          </h1>
        </div>
      </div>
      {
      belts.nodes.map((belt) => (
        <div key={`${belt.name}-${belt.id}`}>
          <Divider title={belt.name} />
          <h3 className="py-4 text-4xl font-bold leading-6 text-center text-gray-800">{belt.name}</h3>
          <p className="text-sm italic text-center text-gray-600 ">* Classes are divided by age and skill level</p>
          <dl className="grid grid-cols-1 mt-5 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
            {belt.classes.map((item) => (
              <div key={`${belt.name}-${item.id}`} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-center text-gray-900">{item.name}</dt>
                <dd className="block">
                  <div className="flex items-baseline justify-center text-2xl font-semibold text-blue-700">
                    <p className="mx-auto text-center">
                      {timeConverter(item.start)}
                      {' '}
                      -
                      {' '}
                      {timeConverter(item.end)}
                    </p>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
          <div className="py-4 text-center">
            <h4>
              Learn more about our
              {' '}
              {belt.name}
              {' '}
              classes
            </h4>
            <div className="inline-flex justify-center m-8 rounded-md shadow">
              <Link
                to={`/class/${belt.slug.current}s`}
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
              >
                <InformationCircleIcon className="w-6 h-6 pr-2" />
                Learn More
              </Link>
            </div>
          </div>
        </div>
      ))
      }
      <Special path={path} />
      <ContactUs />
    </>
  );
}
